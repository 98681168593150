import React from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  PageWrapper,
  TextWrapper,
  HeadingWrapper,
  FlexWrapper,
  FlexItem,
} from "../components/contentwrappers"
import { kebabCase } from "lodash"
import { FaBuilding, FaPhone, FaEnvelope } from "react-icons/fa"
import Helmet from "react-helmet"

import Img from "gatsby-image"

import ContactForm from "../components/contact-form/contact-form"

import ShareButtons from "../components/share/sharebuttons"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const url = typeof window !== "undefined" ? window.location.href : ""




  return (
    <div>
      <Helmet>
        <meta
          property="og:image"
          content={post.frontmatter.featuredImage.childImageSharp.fluid.src}
        />
      </Helmet>
      <BackgroundImage
        fluid={data.royalCourtsImage.childImageSharp.fluid}
        alt="The Royal Courts of Justice"
        style={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "-9",
        }}
      ></BackgroundImage>
      <Layout>
        <SEO title={post.frontmatter.title} />
        <PageWrapper>
          <HeadingWrapper>
            <h1>{post.frontmatter.title}</h1>
            <small>{post.frontmatter.date}</small>
          </HeadingWrapper>

          <TextWrapper>
            <em>
              <p>{post.frontmatter.description}</p>
            </em>

            <Img
              fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
              style={{ marginBottom: "1em" }}
            />

            <div dangerouslySetInnerHTML={{ __html: post.html }} />

            <div style={{ textAlign: "center" }}>
              <p>
                <strong>Share the article</strong>
              </p>
              <ShareButtons
                title={post.frontmatter.title}
                url={url}
              ></ShareButtons>
            </div>
            {post.frontmatter.tags && post.frontmatter.tags.length ? (
              <>
                <hr></hr>
                <p>Read more of our articles on similair topics</p>
                <ul className="taglist">
                  {post.frontmatter.tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </TextWrapper>

          <TextWrapper>
            <h2>
              <strong>Contact</strong> us
            </h2>
            <ContactForm></ContactForm>
            <FlexWrapper>
              <FlexItem flexMe="1.5">
                <div style={{ textAlign: "center" }}>
                  <strong style={{ display: "block" }}>
                    <FaBuilding style={{ marginRight: "1em" }} />
                    Address
                  </strong>{" "}
                  1st Floor, Avenue House <br /> 42-44 Rosemary Street <br />{" "}
                  Belfast, BT1 1QE
                </div>
              </FlexItem>
              <FlexItem flexMe="1.5">
                <div style={{ textAlign: "center" }}>
                  <strong style={{ display: "block" }}>
                    <FaEnvelope style={{ marginRight: "1em" }} />
                    Email
                  </strong>{" "}
                  <a
                    style={{ color: "white" }}
                    hRef={
                      post.frontmatter.type === "enformer"
                        ? "mailTo:info@lsnifa.com"
                        : "mailTo:findingtruewealth@lsnifa.com"
                    }
                  >
                    {post.frontmatter.type === "enformer"
                      ? "info@lsnifa.com"
                      : "findingtruewealth@lsnifa.com"}
                  </a>
                </div>
              </FlexItem>
              <FlexItem flexMe="1.5">
                <div style={{ textAlign: "center" }}>
                  <strong style={{ display: "block" }}>
                    <FaPhone style={{ marginRight: "1em" }} />
                    Phone
                  </strong>{" "}
                  028 9023 0696
                </div>
              </FlexItem>
            </FlexWrapper>
          </TextWrapper>
        </PageWrapper>
      </Layout>
    </div>
  )
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        type
        description
        date(formatString: "MMMM YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 860) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    royalCourtsImage: file(
      relativePath: { eq: "royal-courts-of-justice.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 5031, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
