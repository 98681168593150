import React from "react"

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"






const ShareButtons = ({ title, url }) => {

// log the user clicking the share the article buttons
// uses the Tinybird script that is loaded on each page via the Layout component
function logShareArticleLink(method){
  Tinybird.trackEvent('click_share_article_link', {
    href: window.location.href,
    method: method
    //timestamp and session-id auto added by tinybird
  });
}

  return (
    <div>
      <FacebookShareButton
        url={url}
        title={title}
        style={{ marginRight: "1em" }}
        onClick={()=>logShareArticleLink('facebook')}
      >
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={title}
        style={{ marginRight: "1em" }}
        onClick={()=>logShareArticleLink('twitter')}
      >
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton
        url={url}
        title={title}
        style={{ marginRight: "1em" }}
        onClick={()=>logShareArticleLink('linkedin')}
      >
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>

      <WhatsappShareButton
        url={url}
        title={title}
        style={{ marginRight: "1em" }}
        onClick={()=>logShareArticleLink('whatsapp')}
      >
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>

      <EmailShareButton url={url} title={title} subject={title} onClick={()=>logShareArticleLink('email')}>
        <EmailIcon size={40} round={true} />
      </EmailShareButton>
    </div>
  )
}
export default ShareButtons
